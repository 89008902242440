import React from "react";
import { useRouter } from "next/router";

import { useFormatMessage } from "utils/locales";

import { Button } from "components/Button";
import { Header } from "components/PageHeader";
import Link from "next/link";

export default function NotFound() {
  // Opinionated: do not record an exception in Sentry for 404
  const formatMessage = useFormatMessage();

  const router = useRouter();

  return (
    <>
      <Header isSearchPage={true} />
      <section className="max-w-3xl m-auto min-h-80v mobile:px-6 tablet:px-4">
        <div className="flex flex-col justify-center text-center">
          <h1
            data-cy="404page.title"
            className="mt-48 text-2xl !leading-loose mobile:text-2xl tablet:text-2xl desktop:text-2xl mobile:mt-40 text-big-title"
          >
            {formatMessage("package_notfound_message")} :{" "}
            <b className="text-3xl">{router.query.trackingKey}</b>
          </h1>
          <div className="mt-6" />
          <Link href="/">
            <Button>{formatMessage("package_notfound_button_label")}</Button>
          </Link>
        </div>
      </section>
      <footer className="mt-auto mobile:px-6 tablet:px-4">
        <div className="w-full h-20 p-5">
          <div className="flex flex-wrap overflow-hidden">
            <img className="pl-2 mobile:pl-0" src="./logo-keyopstech.svg" alt="keyops" />
            <Link href="https://keyops.tech">
              <h1 className="float-right pr-2 ml-auto text-lg cursor-pointer text-label-input mobile:pr-0">
                keyops.tech
              </h1>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}
